import styled from '@emotion/styled';

import { orderedBreakpointKeys, screen } from '@/components/common/breakpoints';

import type { BorderColourTokenType } from '@/types/colours';

type Orientation = 'horizontal' | 'vertical' | 'none';

type OrientationProps = {
  base?: Orientation;
  sm?: Orientation;
  md?: Orientation;
  lg?: Orientation;
  xl?: Orientation;
};

export interface DividerProps {
  orientation?: OrientationProps | Orientation;
  role?: string;
  colour?: BorderColourTokenType;
}

export const Divider = ({
  orientation,
  role = 'separator',
  colour = '--border-warm-subtle',
}: DividerProps) => {
  const orientationProps: OrientationProps =
    typeof orientation === 'string' ? { base: orientation } : orientation || {};

  return (
    <DividerElement
      role={role}
      orientation={orientationProps}
      colour={colour}
    />
  );
};

const orientationStyles: Record<Orientation, string> = {
  horizontal: `
    height: 1px;
    width: 100%;
    display: block;
  `,
  vertical: `
    height: 100%;
    width: 1px;
    display: block;
  `,
  none: `
    display: none;
  `,
};

const getOrientationBreakpoints = (orientation: OrientationProps) => {
  const styles = [];

  orderedBreakpointKeys.forEach((breakpoint) => {
    const orientationValue = orientation[breakpoint as keyof OrientationProps];

    if (breakpoint === 'base' && orientationValue) {
      styles.push(orientationStyles[orientationValue]);
    }

    if (orientationValue) {
      styles.push(`
        ${screen[breakpoint]} {
           ${orientationStyles[orientationValue]}
          }
      `);
    }
  });

  return styles.join(' ');
};

const DividerElement = styled.hr<Pick<DividerProps, 'orientation' | 'colour'>>`
  background-color: ${({ colour }) => `var(${colour})`};
  border: none;
  margin: 0;

  ${({ orientation }) =>
    typeof orientation === 'string'
      ? orientationStyles[orientation]
      : getOrientationBreakpoints(orientation)}
`;
